/*--------------------------------

Lineicons regular free icon font

-------------------------------- */

@font-face {
  font-family: 'Lineicons';
  src: url(d495aba62101f856c80f.eot);
  src: url(d495aba62101f856c80f.eot) format('embedded-opentype'),
       url(9065bfe481020b04b68a.woff2) format('woff2'),
       url(68caad34b1e80fa12b3a.woff) format('woff'),
       url(a9c9056a643325579e2a.ttf) format('truetype'),
       url(6b042d50787d4a81d659.svg) format('svg');
}

/* base class */
.lni {
  display: inline-block;
  font: normal normal normal 1em/1 'Lineicons';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.lni-sm {
  font-size: 0.8em;
}

.lni-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.lni-16 {
  font-size: 16px;
}

.lni-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.lni-is-spinning {
  animation: lni-spin 1s infinite linear;
}

@keyframes lni-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.lni-rotate-90 {
  transform: rotate(90deg);
}

.lni-rotate-180 {
  transform: rotate(180deg);
}

.lni-rotate-270 {
  transform: rotate(270deg);
}

.lni-flip-y {
  transform: scaleY(-1);
}

.lni-flip-x {
  transform: scaleX(-1);
}

/* icons */
.lni-500px::before {
  content: "\ea01";
}

.lni-adobe::before {
  content: "\ea02";
}

.lni-adonis::before {
  content: "\ea03";
}

.lni-aeroplane-1::before {
  content: "\ea04";
}

.lni-agenda::before {
  content: "\ea05";
}

.lni-airbnb::before {
  content: "\ea06";
}

.lni-airtable::before {
  content: "\ea07";
}

.lni-alarm-1::before {
  content: "\ea08";
}

.lni-align-text-center::before {
  content: "\ea09";
}

.lni-align-text-left::before {
  content: "\ea0a";
}

.lni-align-text-right::before {
  content: "\ea0b";
}

.lni-alpinejs::before {
  content: "\ea0c";
}

.lni-amazon::before {
  content: "\ea0d";
}

.lni-amazon-original::before {
  content: "\ea0e";
}

.lni-amazon-pay::before {
  content: "\ea0f";
}

.lni-ambulance-1::before {
  content: "\ea10";
}

.lni-amd::before {
  content: "\ea11";
}

.lni-amex::before {
  content: "\ea12";
}

.lni-anchor::before {
  content: "\ea13";
}

.lni-android::before {
  content: "\ea14";
}

.lni-android-old::before {
  content: "\ea15";
}

.lni-angellist::before {
  content: "\ea16";
}

.lni-angle-double-down::before {
  content: "\ea17";
}

.lni-angle-double-left::before {
  content: "\ea18";
}

.lni-angle-double-right::before {
  content: "\ea19";
}

.lni-angle-double-up::before {
  content: "\ea1a";
}

.lni-angular::before {
  content: "\ea1b";
}

.lni-app-store::before {
  content: "\ea1c";
}

.lni-apple-brand::before {
  content: "\ea1d";
}

.lni-apple-music::before {
  content: "\ea1e";
}

.lni-apple-music-alt::before {
  content: "\ea1f";
}

.lni-apple-pay::before {
  content: "\ea20";
}

.lni-arc-browser::before {
  content: "\ea21";
}

.lni-arrow-all-direction::before {
  content: "\ea22";
}

.lni-arrow-angular-top-left::before {
  content: "\ea23";
}

.lni-arrow-angular-top-right::before {
  content: "\ea24";
}

.lni-arrow-both-direction-horizontal-1::before {
  content: "\ea25";
}

.lni-arrow-both-direction-vertical-1::before {
  content: "\ea26";
}

.lni-arrow-downward::before {
  content: "\ea27";
}

.lni-arrow-left::before {
  content: "\ea28";
}

.lni-arrow-left-circle::before {
  content: "\ea29";
}

.lni-arrow-right::before {
  content: "\ea2a";
}

.lni-arrow-right-circle::before {
  content: "\ea2b";
}

.lni-arrow-upward::before {
  content: "\ea2c";
}

.lni-asana::before {
  content: "\ea2d";
}

.lni-astro::before {
  content: "\ea2e";
}

.lni-atlassian::before {
  content: "\ea2f";
}

.lni-audi::before {
  content: "\ea30";
}

.lni-audi-alt::before {
  content: "\ea31";
}

.lni-aws::before {
  content: "\ea32";
}

.lni-azure::before {
  content: "\ea33";
}

.lni-badge-decagram-percent::before {
  content: "\ea34";
}

.lni-balloons::before {
  content: "\ea35";
}

.lni-ban-2::before {
  content: "\ea36";
}

.lni-bar-chart-4::before {
  content: "\ea37";
}

.lni-bar-chart-dollar::before {
  content: "\ea38";
}

.lni-basket-shopping-3::before {
  content: "\ea39";
}

.lni-beat::before {
  content: "\ea3a";
}

.lni-behance::before {
  content: "\ea3b";
}

.lni-bell-1::before {
  content: "\ea3c";
}

.lni-bike::before {
  content: "\ea3d";
}

.lni-bing::before {
  content: "\ea3e";
}

.lni-bitbucket::before {
  content: "\ea3f";
}

.lni-bitcoin::before {
  content: "\ea40";
}

.lni-bittorrent::before {
  content: "\ea41";
}

.lni-blogger::before {
  content: "\ea42";
}

.lni-blogger-alt::before {
  content: "\ea43";
}

.lni-bluetooth::before {
  content: "\ea44";
}

.lni-bluetooth-logo::before {
  content: "\ea45";
}

.lni-bmw::before {
  content: "\ea46";
}

.lni-board-writing-3::before {
  content: "\ea47";
}

.lni-bold::before {
  content: "\ea48";
}

.lni-bolt-2::before {
  content: "\ea49";
}

.lni-bolt-3::before {
  content: "\ea4a";
}

.lni-book-1::before {
  content: "\ea4b";
}

.lni-bookmark-1::before {
  content: "\ea4c";
}

.lni-bookmark-circle::before {
  content: "\ea4d";
}

.lni-books-2::before {
  content: "\ea4e";
}

.lni-bootstrap-5::before {
  content: "\ea4f";
}

.lni-bootstrap-5-square::before {
  content: "\ea50";
}

.lni-box-archive-1::before {
  content: "\ea51";
}

.lni-box-closed::before {
  content: "\ea52";
}

.lni-box-gift-1::before {
  content: "\ea53";
}

.lni-brave::before {
  content: "\ea54";
}

.lni-bricks::before {
  content: "\ea55";
}

.lni-bridge-3::before {
  content: "\ea56";
}

.lni-briefcase-1::before {
  content: "\ea57";
}

.lni-briefcase-2::before {
  content: "\ea58";
}

.lni-briefcase-plus-1::before {
  content: "\ea59";
}

.lni-brush-1-rotated::before {
  content: "\ea5a";
}

.lni-brush-2::before {
  content: "\ea5b";
}

.lni-btc::before {
  content: "\ea5c";
}

.lni-bug-1::before {
  content: "\ea5d";
}

.lni-buildings-1::before {
  content: "\ea5e";
}

.lni-bulb-2::before {
  content: "\ea5f";
}

.lni-bulb-4::before {
  content: "\ea60";
}

.lni-burger-1::before {
  content: "\ea61";
}

.lni-burger-drink::before {
  content: "\ea62";
}

.lni-bus-1::before {
  content: "\ea63";
}

.lni-busket-ball::before {
  content: "\ea64";
}

.lni-cake-1::before {
  content: "\ea65";
}

.lni-calculator-1::before {
  content: "\ea66";
}

.lni-calculator-2::before {
  content: "\ea67";
}

.lni-calendar-days::before {
  content: "\ea68";
}

.lni-camera-1::before {
  content: "\ea69";
}

.lni-camera-movie-1::before {
  content: "\ea6a";
}

.lni-candy-cane-2::before {
  content: "\ea6b";
}

.lni-candy-round-1::before {
  content: "\ea6c";
}

.lni-canva::before {
  content: "\ea6d";
}

.lni-capsule-1::before {
  content: "\ea6e";
}

.lni-car-2::before {
  content: "\ea6f";
}

.lni-car-4::before {
  content: "\ea70";
}

.lni-car-6::before {
  content: "\ea71";
}

.lni-caravan-1::before {
  content: "\ea72";
}

.lni-cart-1::before {
  content: "\ea73";
}

.lni-cart-2::before {
  content: "\ea74";
}

.lni-cash-app::before {
  content: "\ea75";
}

.lni-certificate-badge-1::before {
  content: "\ea76";
}

.lni-chat-bubble-2::before {
  content: "\ea77";
}

.lni-check::before {
  content: "\ea78";
}

.lni-check-circle-1::before {
  content: "\ea79";
}

.lni-check-square-2::before {
  content: "\ea7a";
}

.lni-chevron-down::before {
  content: "\ea7b";
}

.lni-chevron-down-circle::before {
  content: "\ea7c";
}

.lni-chevron-left::before {
  content: "\ea7d";
}

.lni-chevron-left-circle::before {
  content: "\ea7e";
}

.lni-chevron-right-circle::before {
  content: "\ea7f";
}

.lni-chevron-up::before {
  content: "\ea80";
}

.lni-chevron-up-circle::before {
  content: "\ea81";
}

.lni-chrome::before {
  content: "\ea82";
}

.lni-chromecast::before {
  content: "\ea83";
}

.lni-cisco::before {
  content: "\ea84";
}

.lni-claude::before {
  content: "\ea85";
}

.lni-clickup::before {
  content: "\ea86";
}

.lni-clipboard::before {
  content: "\ea87";
}

.lni-cloud-2::before {
  content: "\ea88";
}

.lni-cloud-bolt-1::before {
  content: "\ea89";
}

.lni-cloud-bolt-2::before {
  content: "\ea8a";
}

.lni-cloud-check-circle::before {
  content: "\ea8b";
}

.lni-cloud-download::before {
  content: "\ea8c";
}

.lni-cloud-iot-2::before {
  content: "\ea8d";
}

.lni-cloud-rain::before {
  content: "\ea8e";
}

.lni-cloud-refresh-clockwise::before {
  content: "\ea8f";
}

.lni-cloud-sun::before {
  content: "\ea90";
}

.lni-cloud-upload::before {
  content: "\ea91";
}

.lni-cloudflare::before {
  content: "\ea92";
}

.lni-code-1::before {
  content: "\ea93";
}

.lni-code-s::before {
  content: "\ea94";
}

.lni-codepen::before {
  content: "\ea95";
}

.lni-coffee-cup-2::before {
  content: "\ea96";
}

.lni-coinbase::before {
  content: "\ea97";
}

.lni-colour-palette-3::before {
  content: "\ea98";
}

.lni-comment-1::before {
  content: "\ea99";
}

.lni-comment-1-share::before {
  content: "\ea9a";
}

.lni-comment-1-text::before {
  content: "\ea9b";
}

.lni-compass-drafting-2::before {
  content: "\ea9c";
}

.lni-connectdevelop::before {
  content: "\ea9d";
}

.lni-copilot::before {
  content: "\ea9e";
}

.lni-coral::before {
  content: "\ea9f";
}

.lni-cpanel::before {
  content: "\eaa0";
}

.lni-crane-4::before {
  content: "\eaa1";
}

.lni-creative-commons::before {
  content: "\eaa2";
}

.lni-credit-card-multiple::before {
  content: "\eaa3";
}

.lni-crop-2::before {
  content: "\eaa4";
}

.lni-crown-3::before {
  content: "\eaa5";
}

.lni-css3::before {
  content: "\eaa6";
}

.lni-dashboard-square-1::before {
  content: "\eaa7";
}

.lni-database-2::before {
  content: "\eaa8";
}

.lni-deno::before {
  content: "\eaa9";
}

.lni-dev::before {
  content: "\eaaa";
}

.lni-dialogflow::before {
  content: "\eaab";
}

.lni-diamonds-1::before {
  content: "\eaac";
}

.lni-diamonds-2::before {
  content: "\eaad";
}

.lni-digitalocean::before {
  content: "\eaae";
}

.lni-diners-club::before {
  content: "\eaaf";
}

.lni-direction-ltr::before {
  content: "\eab0";
}

.lni-direction-rtl::before {
  content: "\eab1";
}

.lni-discord::before {
  content: "\eab2";
}

.lni-discord-chat::before {
  content: "\eab3";
}

.lni-discover::before {
  content: "\eab4";
}

.lni-docker::before {
  content: "\eab5";
}

.lni-dollar::before {
  content: "\eab6";
}

.lni-dollar-circle::before {
  content: "\eab7";
}

.lni-double-quotes-end-1::before {
  content: "\eab8";
}

.lni-download-1::before {
  content: "\eab9";
}

.lni-download-circle-1::before {
  content: "\eaba";
}

.lni-dribbble::before {
  content: "\eabb";
}

.lni-dribbble-symbol::before {
  content: "\eabc";
}

.lni-drizzle::before {
  content: "\eabd";
}

.lni-dropbox::before {
  content: "\eabe";
}

.lni-drupal::before {
  content: "\eabf";
}

.lni-dumbbell-1::before {
  content: "\eac0";
}

.lni-edge::before {
  content: "\eac1";
}

.lni-emoji-expressionless::before {
  content: "\eac2";
}

.lni-emoji-expressionless-flat-eyes::before {
  content: "\eac3";
}

.lni-emoji-grin::before {
  content: "\eac4";
}

.lni-emoji-sad::before {
  content: "\eac5";
}

.lni-emoji-smile::before {
  content: "\eac6";
}

.lni-emoji-smile-side::before {
  content: "\eac7";
}

.lni-emoji-smile-sunglass::before {
  content: "\eac8";
}

.lni-emoji-smile-tongue::before {
  content: "\eac9";
}

.lni-enter::before {
  content: "\eaca";
}

.lni-enter-down::before {
  content: "\eacb";
}

.lni-envato::before {
  content: "\eacc";
}

.lni-envelope-1::before {
  content: "\eacd";
}

.lni-eraser-1::before {
  content: "\eace";
}

.lni-ethereum-logo::before {
  content: "\eacf";
}

.lni-euro::before {
  content: "\ead0";
}

.lni-exit::before {
  content: "\ead1";
}

.lni-exit-up::before {
  content: "\ead2";
}

.lni-expand-arrow-1::before {
  content: "\ead3";
}

.lni-expand-square-4::before {
  content: "\ead4";
}

.lni-expressjs::before {
  content: "\ead5";
}

.lni-eye::before {
  content: "\ead6";
}

.lni-facebook::before {
  content: "\ead7";
}

.lni-facebook-messenger::before {
  content: "\ead8";
}

.lni-facebook-rounded::before {
  content: "\ead9";
}

.lni-facebook-square::before {
  content: "\eada";
}

.lni-facetime::before {
  content: "\eadb";
}

.lni-figma::before {
  content: "\eadc";
}

.lni-file-format-zip::before {
  content: "\eadd";
}

.lni-file-multiple::before {
  content: "\eade";
}

.lni-file-pencil::before {
  content: "\eadf";
}

.lni-file-plus-circle::before {
  content: "\eae0";
}

.lni-file-question::before {
  content: "\eae1";
}

.lni-file-xmark::before {
  content: "\eae2";
}

.lni-firebase::before {
  content: "\eae3";
}

.lni-firefox::before {
  content: "\eae4";
}

.lni-firework-rocket-4::before {
  content: "\eae5";
}

.lni-fitbit::before {
  content: "\eae6";
}

.lni-flag-1::before {
  content: "\eae7";
}

.lni-flag-2::before {
  content: "\eae8";
}

.lni-flickr::before {
  content: "\eae9";
}

.lni-floppy-disk-1::before {
  content: "\eaea";
}

.lni-flower-2::before {
  content: "\eaeb";
}

.lni-flutter::before {
  content: "\eaec";
}

.lni-folder-1::before {
  content: "\eaed";
}

.lni-ford::before {
  content: "\eaee";
}

.lni-framer::before {
  content: "\eaef";
}

.lni-funnel-1::before {
  content: "\eaf0";
}

.lni-gallery::before {
  content: "\eaf1";
}

.lni-game-pad-modern-1::before {
  content: "\eaf2";
}

.lni-gatsby::before {
  content: "\eaf3";
}

.lni-gauge-1::before {
  content: "\eaf4";
}

.lni-gear-1::before {
  content: "\eaf5";
}

.lni-gears-3::before {
  content: "\eaf6";
}

.lni-gemini::before {
  content: "\eaf7";
}

.lni-git::before {
  content: "\eaf8";
}

.lni-github::before {
  content: "\eaf9";
}

.lni-glass-juice-1::before {
  content: "\eafa";
}

.lni-globe-1::before {
  content: "\eafb";
}

.lni-globe-stand::before {
  content: "\eafc";
}

.lni-go::before {
  content: "\eafd";
}

.lni-goodreads::before {
  content: "\eafe";
}

.lni-google::before {
  content: "\eaff";
}

.lni-google-cloud::before {
  content: "\eb00";
}

.lni-google-drive::before {
  content: "\eb01";
}

.lni-google-meet::before {
  content: "\eb02";
}

.lni-google-pay::before {
  content: "\eb03";
}

.lni-google-wallet::before {
  content: "\eb04";
}

.lni-graduation-cap-1::before {
  content: "\eb05";
}

.lni-grammarly::before {
  content: "\eb06";
}

.lni-hacker-news::before {
  content: "\eb07";
}

.lni-hammer-1::before {
  content: "\eb08";
}

.lni-hammer-2::before {
  content: "\eb09";
}

.lni-hand-mic::before {
  content: "\eb0a";
}

.lni-hand-shake::before {
  content: "\eb0b";
}

.lni-hand-stop::before {
  content: "\eb0c";
}

.lni-hand-taking-dollar::before {
  content: "\eb0d";
}

.lni-hand-taking-leaf-1::before {
  content: "\eb0e";
}

.lni-hand-taking-user::before {
  content: "\eb0f";
}

.lni-hashnode::before {
  content: "\eb10";
}

.lni-hat-chef-3::before {
  content: "\eb11";
}

.lni-headphone-1::before {
  content: "\eb12";
}

.lni-heart::before {
  content: "\eb13";
}

.lni-helicopter-2::before {
  content: "\eb14";
}

.lni-helmet-safety-1::before {
  content: "\eb15";
}

.lni-hierarchy-1::before {
  content: "\eb16";
}

.lni-highlighter-1::before {
  content: "\eb17";
}

.lni-highlighter-2::before {
  content: "\eb18";
}

.lni-home-2::before {
  content: "\eb19";
}

.lni-hospital-2::before {
  content: "\eb1a";
}

.lni-hourglass::before {
  content: "\eb1b";
}

.lni-html5::before {
  content: "\eb1c";
}

.lni-ibm::before {
  content: "\eb1d";
}

.lni-id-card::before {
  content: "\eb1e";
}

.lni-imdb::before {
  content: "\eb1f";
}

.lni-indent::before {
  content: "\eb20";
}

.lni-info::before {
  content: "\eb21";
}

.lni-injection-1::before {
  content: "\eb22";
}

.lni-instagram::before {
  content: "\eb23";
}

.lni-instagram-logotype::before {
  content: "\eb24";
}

.lni-intel::before {
  content: "\eb25";
}

.lni-ios::before {
  content: "\eb26";
}

.lni-island-2::before {
  content: "\eb27";
}

.lni-jaguar::before {
  content: "\eb28";
}

.lni-jamstack::before {
  content: "\eb29";
}

.lni-java::before {
  content: "\eb2a";
}

.lni-javascript::before {
  content: "\eb2b";
}

.lni-jcb::before {
  content: "\eb2c";
}

.lni-joomla::before {
  content: "\eb2d";
}

.lni-jsfiddle::before {
  content: "\eb2e";
}

.lni-key-1::before {
  content: "\eb2f";
}

.lni-keyboard::before {
  content: "\eb30";
}

.lni-knife-fork-1::before {
  content: "\eb31";
}

.lni-kubernetes::before {
  content: "\eb32";
}

.lni-label-dollar-2::before {
  content: "\eb33";
}

.lni-laptop-2::before {
  content: "\eb34";
}

.lni-laptop-phone::before {
  content: "\eb35";
}

.lni-laravel::before {
  content: "\eb36";
}

.lni-layers-1::before {
  content: "\eb37";
}

.lni-layout-26::before {
  content: "\eb38";
}

.lni-layout-9::before {
  content: "\eb39";
}

.lni-leaf-1::before {
  content: "\eb3a";
}

.lni-leaf-6::before {
  content: "\eb3b";
}

.lni-lemon-squeezy::before {
  content: "\eb3c";
}

.lni-life-guard-tube-1::before {
  content: "\eb3d";
}

.lni-line::before {
  content: "\eb3e";
}

.lni-line-dashed::before {
  content: "\eb3f";
}

.lni-line-dotted::before {
  content: "\eb40";
}

.lni-line-height::before {
  content: "\eb41";
}

.lni-lineicons::before {
  content: "\eb42";
}

.lni-link-2-angular-right::before {
  content: "\eb43";
}

.lni-linkedin::before {
  content: "\eb44";
}

.lni-location-arrow-right::before {
  content: "\eb45";
}

.lni-locked-1::before {
  content: "\eb46";
}

.lni-locked-2::before {
  content: "\eb47";
}

.lni-loom::before {
  content: "\eb48";
}

.lni-magento::before {
  content: "\eb49";
}

.lni-magnet::before {
  content: "\eb4a";
}

.lni-mailchimp::before {
  content: "\eb4b";
}

.lni-map-marker-1::before {
  content: "\eb4c";
}

.lni-map-marker-5::before {
  content: "\eb4d";
}

.lni-map-pin-5::before {
  content: "\eb4e";
}

.lni-markdown::before {
  content: "\eb4f";
}

.lni-mastercard::before {
  content: "\eb50";
}

.lni-medium::before {
  content: "\eb51";
}

.lni-medium-alt::before {
  content: "\eb52";
}

.lni-megaphone-1::before {
  content: "\eb53";
}

.lni-menu-cheesburger::before {
  content: "\eb54";
}

.lni-menu-hamburger-1::before {
  content: "\eb55";
}

.lni-menu-meatballs-1::before {
  content: "\eb56";
}

.lni-menu-meatballs-2::before {
  content: "\eb57";
}

.lni-mercedes::before {
  content: "\eb58";
}

.lni-message-2::before {
  content: "\eb59";
}

.lni-message-2-question::before {
  content: "\eb5a";
}

.lni-message-3-text::before {
  content: "\eb5b";
}

.lni-meta::before {
  content: "\eb5c";
}

.lni-meta-alt::before {
  content: "\eb5d";
}

.lni-microphone-1::before {
  content: "\eb5e";
}

.lni-microscope::before {
  content: "\eb5f";
}

.lni-microsoft::before {
  content: "\eb60";
}

.lni-microsoft-edge::before {
  content: "\eb61";
}

.lni-microsoft-teams::before {
  content: "\eb62";
}

.lni-minus::before {
  content: "\eb63";
}

.lni-minus-circle::before {
  content: "\eb64";
}

.lni-mongodb::before {
  content: "\eb65";
}

.lni-monitor::before {
  content: "\eb66";
}

.lni-monitor-code::before {
  content: "\eb67";
}

.lni-monitor-mac::before {
  content: "\eb68";
}

.lni-moon-half-right-5::before {
  content: "\eb69";
}

.lni-mountains-2::before {
  content: "\eb6a";
}

.lni-mouse-2::before {
  content: "\eb6b";
}

.lni-mushroom-1::before {
  content: "\eb6c";
}

.lni-mushroom-5::before {
  content: "\eb6d";
}

.lni-music::before {
  content: "\eb6e";
}

.lni-mysql::before {
  content: "\eb6f";
}

.lni-nasa::before {
  content: "\eb70";
}

.lni-netflix::before {
  content: "\eb71";
}

.lni-netlify::before {
  content: "\eb72";
}

.lni-next-step-2::before {
  content: "\eb73";
}

.lni-nextjs::before {
  content: "\eb74";
}

.lni-nike::before {
  content: "\eb75";
}

.lni-nissan::before {
  content: "\eb76";
}

.lni-nodejs::before {
  content: "\eb77";
}

.lni-nodejs-alt::before {
  content: "\eb78";
}

.lni-notebook-1::before {
  content: "\eb79";
}

.lni-notion::before {
  content: "\eb7a";
}

.lni-npm::before {
  content: "\eb7b";
}

.lni-nuxt::before {
  content: "\eb7c";
}

.lni-nvidia::before {
  content: "\eb7d";
}

.lni-oculus::before {
  content: "\eb7e";
}

.lni-open-ai::before {
  content: "\eb7f";
}

.lni-opera-mini::before {
  content: "\eb80";
}

.lni-oracle::before {
  content: "\eb81";
}

.lni-outdent::before {
  content: "\eb82";
}

.lni-paddle::before {
  content: "\eb83";
}

.lni-page-break-1::before {
  content: "\eb84";
}

.lni-pagination::before {
  content: "\eb85";
}

.lni-paint-bucket::before {
  content: "\eb86";
}

.lni-paint-roller-1::before {
  content: "\eb87";
}

.lni-paperclip-1::before {
  content: "\eb88";
}

.lni-party-flags::before {
  content: "\eb89";
}

.lni-party-spray::before {
  content: "\eb8a";
}

.lni-patreon::before {
  content: "\eb8b";
}

.lni-pause::before {
  content: "\eb8c";
}

.lni-payoneer::before {
  content: "\eb8d";
}

.lni-paypal::before {
  content: "\eb8e";
}

.lni-pen-to-square::before {
  content: "\eb8f";
}

.lni-pencil-1::before {
  content: "\eb90";
}

.lni-pepsi::before {
  content: "\eb91";
}

.lni-phone::before {
  content: "\eb92";
}

.lni-photos::before {
  content: "\eb93";
}

.lni-php::before {
  content: "\eb94";
}

.lni-pie-chart-2::before {
  content: "\eb95";
}

.lni-pilcrow::before {
  content: "\eb96";
}

.lni-pimjo-logo::before {
  content: "\eb97";
}

.lni-pimjo-symbol::before {
  content: "\eb98";
}

.lni-pinterest::before {
  content: "\eb99";
}

.lni-pizza-2::before {
  content: "\eb9a";
}

.lni-placeholder-dollar::before {
  content: "\eb9b";
}

.lni-plantscale::before {
  content: "\eb9c";
}

.lni-play::before {
  content: "\eb9d";
}

.lni-play-store::before {
  content: "\eb9e";
}

.lni-playstation::before {
  content: "\eb9f";
}

.lni-plug-1::before {
  content: "\eba0";
}

.lni-plus::before {
  content: "\eba1";
}

.lni-plus-circle::before {
  content: "\eba2";
}

.lni-pnpm::before {
  content: "\eba3";
}

.lni-postgresql::before {
  content: "\eba4";
}

.lni-postman::before {
  content: "\eba5";
}

.lni-pound::before {
  content: "\eba6";
}

.lni-power-button::before {
  content: "\eba7";
}

.lni-previous-step-2::before {
  content: "\eba8";
}

.lni-printer::before {
  content: "\eba9";
}

.lni-prisma::before {
  content: "\ebaa";
}

.lni-producthunt::before {
  content: "\ebab";
}

.lni-proton-mail-logo::before {
  content: "\ebac";
}

.lni-proton-mail-symbol::before {
  content: "\ebad";
}

.lni-python::before {
  content: "\ebae";
}

.lni-question-mark::before {
  content: "\ebaf";
}

.lni-question-mark-circle::before {
  content: "\ebb0";
}

.lni-quora::before {
  content: "\ebb1";
}

.lni-radis::before {
  content: "\ebb2";
}

.lni-react::before {
  content: "\ebb3";
}

.lni-reddit::before {
  content: "\ebb4";
}

.lni-refresh-circle-1-clockwise::before {
  content: "\ebb5";
}

.lni-refresh-dollar-1::before {
  content: "\ebb6";
}

.lni-refresh-user-1::before {
  content: "\ebb7";
}

.lni-remix-js::before {
  content: "\ebb8";
}

.lni-road-1::before {
  content: "\ebb9";
}

.lni-rocket-5::before {
  content: "\ebba";
}

.lni-route-1::before {
  content: "\ebbb";
}

.lni-rss-right::before {
  content: "\ebbc";
}

.lni-ruler-1::before {
  content: "\ebbd";
}

.lni-ruler-pen::before {
  content: "\ebbe";
}

.lni-rupee::before {
  content: "\ebbf";
}

.lni-safari::before {
  content: "\ebc0";
}

.lni-sanity::before {
  content: "\ebc1";
}

.lni-school-bench-1::before {
  content: "\ebc2";
}

.lni-school-bench-2::before {
  content: "\ebc3";
}

.lni-scissors-1-vertical::before {
  content: "\ebc4";
}

.lni-scoter::before {
  content: "\ebc5";
}

.lni-scroll-down-2::before {
  content: "\ebc6";
}

.lni-search-1::before {
  content: "\ebc7";
}

.lni-search-2::before {
  content: "\ebc8";
}

.lni-search-minus::before {
  content: "\ebc9";
}

.lni-search-plus::before {
  content: "\ebca";
}

.lni-search-text::before {
  content: "\ebcb";
}

.lni-select-cursor-1::before {
  content: "\ebcc";
}

.lni-seo-monitor::before {
  content: "\ebcd";
}

.lni-service-bell-1::before {
  content: "\ebce";
}

.lni-share-1::before {
  content: "\ebcf";
}

.lni-share-1-circle::before {
  content: "\ebd0";
}

.lni-share-2::before {
  content: "\ebd1";
}

.lni-shield-2::before {
  content: "\ebd2";
}

.lni-shield-2-check::before {
  content: "\ebd3";
}

.lni-shield-dollar::before {
  content: "\ebd4";
}

.lni-shift-left::before {
  content: "\ebd5";
}

.lni-shift-right::before {
  content: "\ebd6";
}

.lni-ship-1::before {
  content: "\ebd7";
}

.lni-shirt-1::before {
  content: "\ebd8";
}

.lni-shopify::before {
  content: "\ebd9";
}

.lni-shovel::before {
  content: "\ebda";
}

.lni-shuffle::before {
  content: "\ebdb";
}

.lni-sign-post-left::before {
  content: "\ebdc";
}

.lni-signal-app::before {
  content: "\ebdd";
}

.lni-signs-post-2::before {
  content: "\ebde";
}

.lni-sketch::before {
  content: "\ebdf";
}

.lni-skype::before {
  content: "\ebe0";
}

.lni-slack::before {
  content: "\ebe1";
}

.lni-slice-2::before {
  content: "\ebe2";
}

.lni-sliders-horizontal-square-2::before {
  content: "\ebe3";
}

.lni-slideshare::before {
  content: "\ebe4";
}

.lni-snapchat::before {
  content: "\ebe5";
}

.lni-sort-alphabetical::before {
  content: "\ebe6";
}

.lni-sort-high-to-low::before {
  content: "\ebe7";
}

.lni-soundcloud::before {
  content: "\ebe8";
}

.lni-spacex::before {
  content: "\ebe9";
}

.lni-spellcheck::before {
  content: "\ebea";
}

.lni-spinner-2-sacle::before {
  content: "\ebeb";
}

.lni-spinner-3::before {
  content: "\ebec";
}

.lni-sports::before {
  content: "\ebed";
}

.lni-spotify::before {
  content: "\ebee";
}

.lni-spotify-alt::before {
  content: "\ebef";
}

.lni-squarespace::before {
  content: "\ebf0";
}

.lni-stackoverflow::before {
  content: "\ebf1";
}

.lni-stamp::before {
  content: "\ebf2";
}

.lni-star-fat::before {
  content: "\ebf3";
}

.lni-star-fat-half-2::before {
  content: "\ebf4";
}

.lni-star-sharp-disabled::before {
  content: "\ebf5";
}

.lni-statista::before {
  content: "\ebf6";
}

.lni-steam::before {
  content: "\ebf7";
}

.lni-stethoscope-1::before {
  content: "\ebf8";
}

.lni-stopwatch::before {
  content: "\ebf9";
}

.lni-storage-hdd-2::before {
  content: "\ebfa";
}

.lni-strikethrough-1::before {
  content: "\ebfb";
}

.lni-stripe::before {
  content: "\ebfc";
}

.lni-stumbleupon::before {
  content: "\ebfd";
}

.lni-sun-1::before {
  content: "\ebfe";
}

.lni-supabase::before {
  content: "\ebff";
}

.lni-surfboard-2::before {
  content: "\ec00";
}

.lni-svelte::before {
  content: "\ec01";
}

.lni-swift::before {
  content: "\ec02";
}

.lni-tab::before {
  content: "\ec03";
}

.lni-tailwindcss::before {
  content: "\ec04";
}

.lni-target-user::before {
  content: "\ec05";
}

.lni-telegram::before {
  content: "\ec06";
}

.lni-telephone-1::before {
  content: "\ec07";
}

.lni-telephone-3::before {
  content: "\ec08";
}

.lni-tesla::before {
  content: "\ec09";
}

.lni-text-format::before {
  content: "\ec0a";
}

.lni-text-format-remove::before {
  content: "\ec0b";
}

.lni-text-paragraph::before {
  content: "\ec0c";
}

.lni-thumbs-down-3::before {
  content: "\ec0d";
}

.lni-thumbs-up-3::before {
  content: "\ec0e";
}

.lni-ticket-1::before {
  content: "\ec0f";
}

.lni-tickets-3::before {
  content: "\ec10";
}

.lni-tiktok::before {
  content: "\ec11";
}

.lni-tiktok-alt::before {
  content: "\ec12";
}

.lni-tower-broadcast-1::before {
  content: "\ec13";
}

.lni-toyota::before {
  content: "\ec14";
}

.lni-train-1::before {
  content: "\ec15";
}

.lni-train-3::before {
  content: "\ec16";
}

.lni-trash-3::before {
  content: "\ec17";
}

.lni-tree-2::before {
  content: "\ec18";
}

.lni-trees-3::before {
  content: "\ec19";
}

.lni-trello::before {
  content: "\ec1a";
}

.lni-trend-down-1::before {
  content: "\ec1b";
}

.lni-trend-up-1::before {
  content: "\ec1c";
}

.lni-trophy-1::before {
  content: "\ec1d";
}

.lni-trowel-1::before {
  content: "\ec1e";
}

.lni-truck-delivery-1::before {
  content: "\ec1f";
}

.lni-tumblr::before {
  content: "\ec20";
}

.lni-turborepo::before {
  content: "\ec21";
}

.lni-twitch::before {
  content: "\ec22";
}

.lni-twitter-old::before {
  content: "\ec23";
}

.lni-typescript::before {
  content: "\ec24";
}

.lni-uber::before {
  content: "\ec25";
}

.lni-uber-symbol::before {
  content: "\ec26";
}

.lni-ubuntu::before {
  content: "\ec27";
}

.lni-underline::before {
  content: "\ec28";
}

.lni-unlink-2-angular-eft::before {
  content: "\ec29";
}

.lni-unlocked-2::before {
  content: "\ec2a";
}

.lni-unsplash::before {
  content: "\ec2b";
}

.lni-upload-1::before {
  content: "\ec2c";
}

.lni-upload-circle-1::before {
  content: "\ec2d";
}

.lni-user-4::before {
  content: "\ec2e";
}

.lni-user-multiple-4::before {
  content: "\ec2f";
}

.lni-vector-nodes-6::before {
  content: "\ec30";
}

.lni-vector-nodes-7::before {
  content: "\ec31";
}

.lni-vercel::before {
  content: "\ec32";
}

.lni-vimeo::before {
  content: "\ec33";
}

.lni-visa::before {
  content: "\ec34";
}

.lni-vite::before {
  content: "\ec35";
}

.lni-vk::before {
  content: "\ec36";
}

.lni-vmware::before {
  content: "\ec37";
}

.lni-volkswagen::before {
  content: "\ec38";
}

.lni-volume-1::before {
  content: "\ec39";
}

.lni-volume-high::before {
  content: "\ec3a";
}

.lni-volume-low::before {
  content: "\ec3b";
}

.lni-volume-mute::before {
  content: "\ec3c";
}

.lni-volume-off::before {
  content: "\ec3d";
}

.lni-vs-code::before {
  content: "\ec3e";
}

.lni-vuejs::before {
  content: "\ec3f";
}

.lni-wallet-1::before {
  content: "\ec40";
}

.lni-watch-beat-1::before {
  content: "\ec41";
}

.lni-water-drop-1::before {
  content: "\ec42";
}

.lni-webflow::before {
  content: "\ec43";
}

.lni-webhooks::before {
  content: "\ec44";
}

.lni-wechat::before {
  content: "\ec45";
}

.lni-weight-machine-1::before {
  content: "\ec46";
}

.lni-whatsapp::before {
  content: "\ec47";
}

.lni-wheelbarrow-empty::before {
  content: "\ec48";
}

.lni-wheelchair-1::before {
  content: "\ec49";
}

.lni-windows::before {
  content: "\ec4a";
}

.lni-wise::before {
  content: "\ec4b";
}

.lni-wordpress::before {
  content: "\ec4c";
}

.lni-www::before {
  content: "\ec4d";
}

.lni-www-cursor::before {
  content: "\ec4e";
}

.lni-x::before {
  content: "\ec4f";
}

.lni-xampp::before {
  content: "\ec50";
}

.lni-xbox::before {
  content: "\ec51";
}

.lni-xmark::before {
  content: "\ec52";
}

.lni-xmark-circle::before {
  content: "\ec53";
}

.lni-xrp::before {
  content: "\ec54";
}

.lni-yahoo::before {
  content: "\ec55";
}

.lni-yarn::before {
  content: "\ec56";
}

.lni-ycombinator::before {
  content: "\ec57";
}

.lni-yen::before {
  content: "\ec58";
}

.lni-youtube::before {
  content: "\ec59";
}

.lni-youtube-kids::before {
  content: "\ec5a";
}

.lni-youtube-music::before {
  content: "\ec5b";
}

.lni-zapier::before {
  content: "\ec5c";
}

.lni-zero-size::before {
  content: "\ec5d";
}

.lni-zoom::before {
  content: "\ec5e";
}

